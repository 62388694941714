import { gql } from "@shane32/graphql";

export const FleetMachineQuery = gql`
  query FleetMachine {
    v1 {
      machineTypes {
        id
        name
      }
      machineAttachments {
        id
        name
      }
      machineManufacturers {
        id
        name
      }
    }
  }
`;

export interface IFleetMachineQuery {
  v1: {
    machineTypes: IMachineType[];
    machineAttachments: IMachineAttachment[];
    machineManufacturers: IMachineManufacturer[];
  };
}

export interface IMachineType {
  id: string;
  name: string;
}

export interface IMachineAttachment {
  id: string;
  name: string;
}

export interface IMachineManufacturer {
  id: string;
  name: string;
}
