import { gql } from "@shane32/graphql";

export const MachineModelQuery = gql`
  query MachineModels($id: ID!) {
    v1 {
      machineModels(id: $id) {
        id
        name
        baseName
        json
        machineManufacturer {
          id
          name
        }
        machineType {
          id
          name
        }
      }
    }
  }
`;

export interface IMachineModelQuery {
  v1: {
    machineModels: IMachineModel[];
  };
}

export interface IMachineModel {
  id: string;
  baseName: string | null;
  name: string;
  machineManufacturer: IMachineManufacturer;
  machineType: IMachineType;
  json: string | null;
}

export interface IMachineManufacturer {
  id: string;
  name: string;
}

export interface IMachineType {
  id: string;
  name: string;
}

export interface IMachineModelQueryVariables {
  id: string;
}
