import connectStats, { StatsConnectorParams, StatsWidgetDescription } from "instantsearch.js/es/connectors/stats/connectStats";
import { useConnector } from "react-instantsearch";
import SideBarPopOut from "../../components/SideBarPopOut/SideBarPopOut";
import AlgoliaSearchFilters from "./Filters";
import styles from "./Filters.module.scss";

interface IProps {
  isOpen: boolean;
  toggleMobileFilters: () => void;
}

const SideBarSearchFilters = (props: IProps) => {
  const { nbHits } = useConnector<StatsConnectorParams, StatsWidgetDescription>(connectStats);
  return (
    <div className={styles["wrapper"]}>
      <SideBarPopOut onlyMobile open={props.isOpen} close={props.toggleMobileFilters}>
        <div className={styles["content"]}>
          <button onClick={props.toggleMobileFilters} className={`btn mb-10 ${styles["close-Btn"]}`}>
            {" "}
            Show Results ({nbHits})
          </button>
          <div className={styles["scrollable"]}>
            <AlgoliaSearchFilters />
          </div>
        </div>
      </SideBarPopOut>
    </div>
  );
};

export default SideBarSearchFilters;
