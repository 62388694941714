import { gql } from "@shane32/graphql";

export const AllCustomerReviewsQuery = gql`
  query AllCustomerReviews($productId: ID) {
    v1 {
      product(id: $productId) {
        reviews {
          items {
            createdOnUtc
            helpfulNoTotal
            helpfulYesTotal
            id
            rating
            replyText
            reviewText
            title
          }
        }
      }
    }
  }
`;

export interface ProductReview {
  createdOnUtc: Date;
  helpfulNoTotal: number;
  helpfulYesTotal: number;
  id: string;
  rating: number;
  replyText: string | null;
  reviewText: string;
  title: string;
}

export interface IAllCustomerReviewsQuery {
  v1: {
    productReviews: ProductReview[];
  };
}

export interface IAllCustomerReviewsQueryVariables {
  productId: string;
}
