import { gql } from "@shane32/graphql";
import { shoppingCartPartialFields, IShoppingCart } from "../queries/ShoppingCartQuery";

export const MoveToCartMutation = gql`
  ${shoppingCartPartialFields}

  mutation MoveToCart($shoppingCartItemId: ID!) {
    v1 {
      cart {
        moveToCart(shoppingCartItemId: $shoppingCartItemId) {
          ...ShoppingCartPartialFields
        }
      }
    }
  }
`;

// Everything below this line is being used currently, but eventually needs
// to switch to use graphql-codegen for types and queries/mutations.
export interface IMoveToCartResult {
  v1: {
    cart: {
      moveToCart: IShoppingCart;
    };
  };
}

export interface IMoveToCartVariables {
  shoppingCartItemId: string;
}
