import { gql } from "@shane32/graphql";
import { IPriceAndAvailability } from "./ProductPageQuery";

export const SimilarItemsSearchQuery = gql`
  query SimilarItemsSearch(
    $relatedManufacturerPartNumber: String
    $sortMethod: SortMethodEnum
    $filters: [IdAndValuesGraph!]
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    v1 {
      searchResults(relatedManufacturerPartNumber: $relatedManufacturerPartNumber) {
        productsConnection(first: $first, last: $last, before: $before, after: $after, sortMethod: $sortMethod) {
          items {
            approvedRatingSum
            approvedTotalReviews
            id
            isFreeShipping
            manufacturerPartNumber
            manufacturers {
              manufacturer {
                name
              }
            }
            name
            priceAndAvailability {
              price
              status
              stock
            }
            picture {
              thumbnail(size: 100) {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export interface ISimilarItemsSearchQuery {
  v1: {
    searchResults: {
      productsConnection: ISearchQuerySimilarProducts;
    };
  };
}

export interface ISearchQuerySimilarProducts {
  items: Array<{
    approvedRatingSum: number;
    approvedTotalReviews: number;
    id: string;
    isFreeShipping: boolean;
    manufacturerPartNumber: string;
    manufacturers: Array<{
      manufacturer: {
        name: string;
      };
    }>;
    name: string;
    priceAndAvailability: IPriceAndAvailability;
    picture: {
      thumbnail: {
        url: string;
      };
    } | null;
  }>;
}
