import React from "react";
import { Link } from "react-router-dom";
import GenerateLink from "../../helpers/GenerateLink";
import { ICollectionData } from "../Collection/Collection";
import styles from "./ProductCartCarousel.module.scss";
import ZboxLogo from "../../images/logo/Zbox-logo.png";

interface IProps {
  products: ICollectionData[];
  seeMoreTo: string;
}

const ProductCartCarousel: React.FC<IProps> = ({ products, seeMoreTo }) => {
  return (
    <div className={styles.carouselContainer}>
      {products.map((product) => {
        const truncatedName =
          product.name.split(" fits")[0].length > 40
            ? product.name.split(" fits")[0].substring(0, 40) + "..."
            : product.name.split(" fits")[0];
        return (
          <div key={product.id} className={styles.productCard}>
            <Link to={GenerateLink.ForProduct(product.id, product.seoName)}>
              <img src={product.picture?.thumbnail?.url || ZboxLogo} alt={truncatedName} className={styles.productImage} loading="lazy" />
              <div className={styles.productPrice}>${product.priceAndAvailability.price}</div>
              <h3 className={styles.productTitle}>{truncatedName}</h3>
            </Link>
          </div>
        );
      })}
      <Link to={seeMoreTo} className={styles.seeMore}>
        <div>See More</div>
      </Link>
    </div>
  );
};

export default ProductCartCarousel;
