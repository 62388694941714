import { gql } from "@shane32/graphql";
import { IAddMachineInterface } from "./AddMachineMutation";
import { IMachineStatusType } from "../../queries/FleetQuery";

export const EditMachineMutation = gql`
  mutation EditMachine($garageId: ID!, $garageItem: GarageInputGraph!) {
    v1 {
      garage {
        edit(garageId: $garageId, garageItem: $garageItem) {
          id
          hours
          machineAttachment {
            id
          }
          machineEngine {
            id
            name
          }
          machineModel {
            baseName
            name
            id
            machineEngines {
              id
              name
            }
            machineManufacturer {
              id
              name
            }
            machineType {
              id
              name
            }
            picture {
              id
              imageUrl
              thumbnail(size: 100) {
                url
              }
            }
          }
          machineStatus
          name
          notes
          serialNumber
          sortOrder
          stockNumber
          year
        }
      }
    }
  }
`;

export interface IEditMachineMutationResult {
  v1: {
    garage: {
      edit: IAddMachineInterface;
    };
  };
}

export interface IEditMachineMutationVariables {
  garageId: string;
  garageItem: IGarageItemEdit;
}

export interface IGarageItemEdit {
  hours: number | null;
  machineAttachmentId: string | null;
  machineEngineId: string | null;
  machineStatus: IMachineStatusType;
  name: string | null;
  notes: string | null;
  serialNumber: string | null;
  sortOrder: number;
  stockNumber: string | null;
  year: number | null;
  engine: string | null;
}
