import { gql } from "@shane32/graphql";

// using non-authenticated link

export const ProductShippingMethodsQuery = gql`
  query ProductShippingMethods($productId: ID, $countryId: ID!, $provinceId: ID, $postalCode: String) {
    v1 {
      product(id: $productId) {
        shippingOptions(countryId: $countryId, provinceId: $provinceId, postalCode: $postalCode) {
          name
          price
          eta
        }
      }
    }
  }
`;

export interface IProductShippingMethodsQuery {
  v1: {
    product: {
      shippingOptions: IShippingOptions[] | null;
    };
  };
}

export interface IShippingOptions {
  name: string;
  price: number;
  eta: string | null;
}

export interface IProductShippingMethodsQueryVariables {
  productId: string;
  countryId: string;
  provinceId: string | null;
  postalCode: string;
}
