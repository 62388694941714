import { gql } from "@shane32/graphql";

export const DeletePaymentMutation = gql`
  mutation DeletePayment($paymentId: ID!) {
    v1 {
      paymentMethod {
        delete(id: $paymentId)
      }
    }
  }
`;

export interface IDeletePaymentMutationResult {
  v1: {
    paymentMethod: {
      delete: boolean;
    };
  };
}

export interface IDeletePaymentMutationVariables {
  paymentId: string;
}
