import { gql } from "@shane32/graphql";

export const ZipCodeTemplateQuery = gql`
  query ZipCodeTemplate {
    v1 {
      info {
        zipCodeTemplate
      }
    }
  }
`;

export interface IZipCodeTemplateQuery {
  v1: {
    info: {
      zipCodeTemplate: string;
    };
  };
}
