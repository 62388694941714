import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import NavSidebar from "../../components/navbar/NavSideBar";
import Overlay from "../../components/overlay/Overlay";
import useHeader from "../../hooks/useHeader";
import CategorySection from "./CategorySection";
import styles from "./Home.module.scss";
import ProductSection from "./ProductSection";

const Home = () => {
  useHeader("ScrollNavBar");
  return (
    <div className={styles["container"]}>
      <Overlay />
      <NavSidebar />
      <div className={styles["landing"]}>
        <Navbar />
      </div>
      <CategorySection />
      <ProductSection />
      <Footer />
    </div>
  );
};

export default Home;
