import { gql } from "@shane32/graphql";

export const FeedbackNameQuery = gql`
  query FeedbackName {
    v1 {
      my {
        feedbackName
      }
    }
  }
`;

export interface IFeedbackNameQuery {
  v1: {
    my: {
      feedbackName: string;
    } | null;
  };
}

export interface IFeedbackNameQueryVariables {}
