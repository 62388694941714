import { gql } from "@shane32/graphql";

export const BraintreeClientTokenQuery = gql`
  query BraintreeClientToken {
    v1 {
      my {
        plugins {
          braintree {
            clientToken
          }
        }
      }
    }
  }
`;

export interface IBraintreeClientTokenQuery {
  v1: {
    my: {
      plugins: {
        braintree: {
          clientToken: string;
        };
      };
    } | null;
  };
}
