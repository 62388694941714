export function Distinct(terms: string[]) {
  var seen: Record<string, number> = {};
  var out = [];
  var len = terms.length;
  var j = 0;
  for (var i = 0; i < len; i++) {
    var item = terms[i];
    if (item) {
      item = item.toLowerCase();
    }
    if (seen[item] !== 1) {
      seen[item] = 1;
      out[j++] = item;
    }
  }

  return out;
}
