import { gql } from "@shane32/graphql";

export const EmailNotificationsQuery = gql`
  query EmailNotifications {
    isAuthenticated
    v1 {
      my {
        email
      }
    }
  }
`;

export interface IEmailNotificationsQuery {
  isAuthenticated: boolean;
  v1: {
    my: {
      email: string;
    };
  };
}

export interface IEmailNotificationsQueryVariables {}
