import { gql } from "@shane32/graphql";

export const AddFeedbackMutation = gql`
  mutation AddFeedback($feedback: FeedbackInputGraph!) {
    v1 {
      leaveFeedback {
        add(feedback: $feedback)
      }
    }
  }
`;

export interface IFeedback {
  productId: number;
  orderId: number;
  vendorId: number | null;
  productReview: IProductReview;
  sellerReview: ISellerReview;
}

export interface IAddFeedbackMutationResult {
  v1: {
    leaveFeedback: {
      add: IFeedback;
    };
  };
}

export interface IAddFeedbackMutationVariables {
  feedback: IFeedback;
}

export interface IProductReview {
  title: string;
  starRating: number;
  review: string;
  suggestion: string | null;
}

export interface ISellerReview {
  asDescribed: boolean | null;
  arrivedOnTime: boolean | null;
  wellPackaged: boolean | null;
  promptService: boolean | null;
}
