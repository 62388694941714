import { gql } from "@shane32/graphql";

// using non-authenticated link

export const ProductPageAlsoPurchasedQuery = gql`
  query ProductPageAlsoPurchased($productId: ID) {
    v1 {
      product(id: $productId) {
        productsAlsoPurchased {
          id
        }
      }
    }
  }
`;

export interface IProductPageAlsoPurchasedQueryVariables {
  productId: string;
}

export interface IProductPageAlsoPurchasedQuery {
  v1: {
    product: {
      productsAlsoPurchased: Array<{
        id: string;
      }>;
    } | null;
  };
}
