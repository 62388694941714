import { gql } from "@shane32/graphql";

export const SetSelectedShippingMethodMutation = gql`
  mutation SetSelectedShippingMethod(
    $countryId: ID!
    $provinceId: ID
    $postalCode: String
    $warehouseId: ID!
    $vendorId: ID!
    $shippingProfileId: ID!
    $backordered: Boolean!
    $selectedShippingMethodId: ID!
  ) {
    v1 {
      cart {
        setSelectedShippingMethod(
          countryId: $countryId
          provinceId: $provinceId
          postalCode: $postalCode
          warehouseId: $warehouseId
          vendorId: $vendorId
          shippingProfileId: $shippingProfileId
          backordered: $backordered
          selectedShippingMethodId: $selectedShippingMethodId
        ) {
          eta
          id
          name
          price
          selected
        }
      }
    }
  }
`;

interface IShoppingCartShippingMethod {
  eta: string;
  id: string;
  name: string;
  price: number;
  selected: boolean;
}

export interface ISetSelectedShippingMethodResult {
  v1: {
    cart: {
      setSelectedShippingMethod: IShoppingCartShippingMethod[];
    };
  };
}

export interface ISetSelectedShippingMethodVariables {
  countryId: string;
  provinceId: string | null;
  postalCode: string | null;
  warehouseId: string;
  vendorId: string;
  shippingProfileId: string;
  backordered: boolean;
  selectedShippingMethodId: string;
}
