import { gql } from "@shane32/graphql";

export const SubmitReturnRequestMutation = gql`
  mutation SubmitReturnRequest($orderId: ID!, $reason: String!) {
    v1 {
      order {
        submitReturnRequest(orderId: $orderId, reason: $reason)
      }
    }
  }
`;

export interface ISubmitReturnRequestMutationResult {
  v1: {
    order: {
      submitReturnRequest: boolean;
    };
  };
}

export interface ISubmitReturnRequestMutationVariables {
  orderId: string;
  reason: string;
}
