import { gql } from "@shane32/graphql";

export const CheckFitmentMutation = gql`
  mutation CheckFitment($productId: ID!, $garageId: ID!) {
    v1 {
      garage {
        fitmentCheck(garageId: $garageId, productId: $productId)
      }
    }
  }
`;

export interface ICheckFitmentMutationResult {
  v1: {
    garage: {
      fitmentCheck: boolean;
    };
  };
}

export interface ICheckFitmentMutationVariables {
  garageId: string;
  productId: string;
}
