import { gql } from "@shane32/graphql";

export const ChangeNameMutation = gql`
  mutation ChangeName($firstName: String!, $lastName: String!) {
    v1 {
      loginAndSecurity {
        changeName(firstName: $firstName, lastName: $lastName)
      }
    }
  }
`;

export interface IChangeNameMutationResult {
  v1: {
    loginAndSecurity: {
      changeName: string;
    };
  };
}

export interface IChangeNameMutationVariables {
  firstName: string;
  lastName: string;
}
