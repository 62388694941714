import { gql } from "@shane32/graphql";
import { shoppingCartPartialFields, IShoppingCart } from "../queries/ShoppingCartQuery";

export const AddProductToCartFromCatalogMutation = gql`
  ${shoppingCartPartialFields}

  mutation AddProductToCartFromCatalog($productId: ID!, $shoppingCartTypeId: ID!, $qty: Int!) {
    v1 {
      cart {
        add(productId: $productId, shoppingCartTypeId: $shoppingCartTypeId, qty: $qty) {
          ...ShoppingCartPartialFields
        }
      }
    }
  }
`;

// Everything below this line is being used currently, but eventually needs
// to switch to use graphql-codegen for types and queries/mutations.
export interface IAddProductToCartFromCatalogResult {
  v1: {
    cart: {
      add: IShoppingCart;
    };
  };
}

export interface IAddProductToCartFromCatalogVariables {
  productId: string;
  qty: number;
  shoppingCartTypeId: string;
}
