import { createInsightsMiddleware } from "instantsearch.js/es/middlewares";
import { useLayoutEffect } from "react";
import { useInstantSearch } from "react-instantsearch";

declare const window: Window & {
  aa: any;
};

export default function InsightsMiddleware() {
  const { addMiddlewares } = useInstantSearch();
  useLayoutEffect(() => {
    const middleware = createInsightsMiddleware({
      insightsClient: window.aa ? window.aa : null,
    });

    return addMiddlewares(middleware);
  }, [addMiddlewares]);

  return null;
}
