import { gql } from "@shane32/graphql";

export const ShoppingCartShippingMethodsQuery = gql`
  query ShoppingCartShippingMethods(
    $countryId: ID!
    $provinceId: ID
    $postalCode: String
    $warehouseId: ID!
    $vendorId: ID!
    $shippingProfileId: ID!
    $backordered: Boolean!
  ) {
    v1 {
      my {
        shoppingCartShippingMethods(
          countryId: $countryId
          provinceId: $provinceId
          postalCode: $postalCode
          warehouseId: $warehouseId
          vendorId: $vendorId
          shippingProfileId: $shippingProfileId
          backordered: $backordered
        ) {
          id
          name
          price
          selected
          eta
        }
      }
    }
  }
`;

export interface IShoppingCartShippingMethod {
  id: string;
  name: string;
  price: number;
  selected: boolean;
  eta: string | null;
}

export interface IShoppingCartShippingMethodsQuery {
  v1: {
    my: {
      shoppingCartShippingMethods: IShoppingCartShippingMethod[];
    } | null;
  };
}

export interface IShoppingCartShippingMethodsQueryVariables {
  countryId: string;
  provinceId: string | null;
  postalCode: string | null;
  warehouseId: string;
  vendorId: string;
  shippingProfileId: string;
  backordered: boolean;
}
