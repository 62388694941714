import { gql } from "@shane32/graphql";

export const AddMachineMutation = gql`
  mutation AddMachine($garageItem: GarageInputGraph!, $setSelected: Boolean!) {
    v1 {
      garage {
        add(garageItem: $garageItem, setSelected: $setSelected) {
          id
          hours
          machineAttachment {
            id
            name
          }
          machineEngine {
            id
            name
          }
          machineModel {
            baseName
            name
            id
            machineEngines {
              id
              name
            }
            machineManufacturer {
              id
              name
            }
            machineType {
              id
              name
            }
            picture {
              id
              imageUrl
              thumbnail(size: 100) {
                url
              }
            }
          }
          machineStatus
          name
          notes
          serialNumber
          sortOrder
          stockNumber
          year
        }
      }
    }
  }
`;

export interface IAddMachineMutationResult {
  v1: {
    garage: {
      add: IAddMachineInterface;
    };
  };
}

export interface IGarageItemAdd {
  machineEngineId: string | null;
  machineModelId: string | null;
  machineStatus: "IN_SERVICE" | "NEEDS_REPAIR" | "DECOMMISSIONED";
  sortOrder: number;
}

export interface IAddMachineMutationVariables {
  setSelected: boolean;
  garageItem: IGarageItemAdd;
}

export interface IAddMachineInterface {
  id: string;
  hours: number | null;
  machineAttachment: {
    id: string;
    name: string;
  } | null;
  machineEngine: {
    id: string;
    name: string;
  } | null;
  machineModel: IMachineModel;
  machineStatus: "IN_SERVICE" | "NEEDS_REPAIR" | "DECOMMISSIONED";
  name: string | null;
  notes: string | null;
  serialNumber: string | null;
  sortOrder: number;
  stockNumber: string | null;
  year: number | null;
}

export interface IMachineModel {
  baseName: string | null;
  name: string | null;
  id: string;
  machineEngines: {
    id: string;
    name: string;
  } | null;
  machineManufacturer: {
    id: string;
    name: string;
  } | null;
  machineType: {
    id: string;
    name: string;
  } | null;
  picture: {
    id: string;
    imageUrl: string;
    thumbnail: {
      url: string;
    };
  } | null;
}
