import { gql } from "@shane32/graphql";

export const QrCodeQuery = gql`
  query QrCode($sku: String!) {
    v1 {
      product(sku: $sku) {
        id
        seoName
      }
    }
  }
`;

export interface IQrCodeQuery {
  v1: {
    product: {
      id: string;
      seoName: string;
    };
  };
}

export interface IQrCodeQueryVariables {
  sku: string;
}
