import { gql } from "@shane32/graphql";
import { IOrderItem } from "../queries/OrdersAndReturnsQuery";

export const FleetQuery = gql`
  query FleetQuery {
    v1 {
      my {
        selectedGarageItem {
          id
        }
        garage {
          id
          name
          orderItems {
            product {
              id
              name
              additionalShippingCharge
              manufacturerPartNumber
              manufacturers {
                displayOrder
                manufacturer {
                  displayOrder
                  id
                  name
                  picture {
                    imageUrl
                  }
                }
              }
              priceAndAvailability {
                allowedQuantities
                backorderAvailabilityRange
                oldPrice
                orderMaximumQuantity
                orderMinimumQuantity
                preorderAvailabilityStartDateTime
                price
                status
                stock
                canNotify
              }
              picture {
                thumbnail(size: 100) {
                  url
                }
              }
              shipSeparately
              sku
              vendor {
                name
              }
            }
            order {
              createdOnUtc
            }
          }
          hours
          machineAttachment {
            id
            name
          }
          machineEngine {
            id
            name
          }
          machineModel {
            id
            name
            baseName
            picture {
              seoFilename
              mimeType
            }
            machineManufacturer {
              id
              name
            }
            machineType {
              id
              name
            }
          }
          notes
          machineStatus
          picture {
            id
            imageUrl
            thumbnail(size: 100) {
              url
            }
          }
          serialNumber
          sortOrder
          stockNumber
          year
          engine
        }
      }
    }
  }
`;

export interface IFleetQuery {
  v1: {
    my: {
      selectedGarageItem: { id: string } | null;
      garage: IFleet[];
    } | null;
  };
}
export interface IMachineAttachment {
  id: string;
  name: string;
}

export interface IMachineManufacturer {
  id: string;
  name: string;
}

export interface IMachineModel {
  id: string;
  name: string;
  baseName: string | null;
  machineManufacturer: IMachineManufacturer;
  machineType: IMachineType;
  picture: IModelPicture | null;
}

export interface IModelPicture {
  seoFilename: string | null;
  mimeType: string | null;
  thumbnail: { url: string | null } | null;
}

export interface IMachineEngine {
  id: string;
  name: string;
}

export interface IMachineType {
  id: string;
  name: string;
}

export interface IFleet {
  id: string;
  name: string | null;
  orderItems: IOrderItem[];
  hours: number | null;
  machineAttachment: IMachineAttachment | null;
  machineEngine: IMachineEngine | null;
  machineModel: IMachineModel;
  notes: string | null;
  machineStatus: IMachineStatusType;
  picture: {
    id: string;
    imageUrl: string;
    thumbnail: {
      url: string | null;
    };
  } | null;
  serialNumber: string | null;
  sortOrder: number;
  stockNumber: string | null;
  year: number | null;
  engine: string | null;
}

export function MachineName(machine: IFleet | null) {
  if (!machine) return "";
  const mfr = machine?.machineModel.machineManufacturer.name;
  const model = machine?.machineModel.name;
  return mfr + " " + model;
}

export type IMachineStatusType = "IN_SERVICE" | "DECOMMISSIONED" | "NEEDS_REPAIR";
