import { Hit as AlgoliaHit } from "@algolia/client-search";
import connectStats, { StatsConnectorParams, StatsWidgetDescription } from "instantsearch.js/es/connectors/stats/connectStats";
import { useConnector } from "react-instantsearch";
import GenerateMeta from "../../helpers/GenerateMeta";

type HitProps = {
  hit: AlgoliaHit<{
    title: string;
    price: number;
    part: string;
    category: string;
    picture: string;
    city: string;
    state: string;
    brand: string;
    stock: string;
  }>;
};

type UseStatsProps = StatsConnectorParams;

export function useStats(props?: UseStatsProps) {
  return useConnector<StatsConnectorParams, StatsWidgetDescription>(connectStats, props);
}

export function Meta(term: string, amount: number) {
  const meta = `
        ${term !== `` ? `“${term}”` : `Zbox`}
        ${amount.toLocaleString("en-us")}
        ${amount === 1 ? ` Part` : ` Parts`}
    `;
  GenerateMeta.Data({
    title: meta,
    description: meta,
  });
}

export function Stats(props: { nbHits: number; query: String }) {
  return (
    <h3 className="m-0 pr-5">
      {props.nbHits.toLocaleString("en-us")}
      {props.nbHits === 1 ? " Part" : " Parts"}
      {props.query !== "" ? ` for 	“${props.query}”` : ""}
    </h3>
  );
}

export function Hits({ hit }: HitProps) {
  return <pre>{hit.toString()}</pre>;
}

export const MainAlgoliaIndex = process.env.REACT_APP_ALGOLIA_MAIN_INDEX ?? "";
