import React, { useState } from "react";
import styles from "./CategorySection.module.scss";
import GenerateLink from "../../helpers/GenerateLink";
import Belts from "../../images/part_type/belt.png";
import Filter from "../../images/part_type/filter.png";
import Glass from "../../images/part_type/glass.png";
import Radiator from "../../images/part_type/radiator.png";
import SealKit from "../../images/part_type/sealkit.png";
import Seat from "../../images/part_type/seat.png";
import Zbox from "../../images/logo/Zbox-logo.png";
import Caterpillar from "../../images/brands/caterpillar.png";
import Volvo from "../../images/brands/volvo.png";
import JohnDeere from "../../images/brands/johndeere.png";
import Case from "../../images/brands/case.png";
import Ford from "../../images/brands/ford.png";
import BushHog from "../../images/brands/bushhog.png";
import MasseyFerguson from "../../images/brands/masseyferguson.png";
import NewHolland from "../../images/brands/newholland.png";
import Bobcat from "../../images/brands/bobcat.png";
import RulerContext from "../../contexts/RulerContext";

const CategorySection: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const ruler = React.useContext(RulerContext);
  const visibleItems = ruler.xs ? 1 : ruler.sm ? 2 : 5;

  const categories = [
    { src: Seat, title: "Seat", link: GenerateLink.ForSearch("seat") },
    { src: Glass, title: "Glass", link: GenerateLink.ForSearch("glass") },
    { src: SealKit, title: "Seal Kits", link: GenerateLink.ForSearch("seal kits") },
    { src: Belts, title: "Belts", link: GenerateLink.ForSearch("belts") },
    { src: Filter, title: "Filters", link: GenerateLink.ForSearch("filters") },
    { src: Radiator, title: "Radiators", link: GenerateLink.ForSearch("radiator") },
  ];

  const manufacturers = [
    { src: Zbox, title: "Zbox", link: GenerateLink.ForSearch("zbox") },
    { src: JohnDeere, title: "John Deere", link: GenerateLink.ForSearch("johndeere") },
    { src: Caterpillar, title: "Caterpillar", link: GenerateLink.ForSearch("caterpillar") },
    { src: NewHolland, title: "New Holland", link: GenerateLink.ForSearch("newholland") },
    { src: Volvo, title: "Volvo", link: GenerateLink.ForSearch("volvo") },
    { src: Case, title: "Case", link: GenerateLink.ForSearch("case") },
    { src: BushHog, title: "Bush Hog", link: GenerateLink.ForSearch("bushhog") },
    { src: MasseyFerguson, title: "Massey Ferguson", link: GenerateLink.ForSearch("masseyferguson") },
    { src: Bobcat, title: "Bobcat", link: GenerateLink.ForSearch("bobcat") },
    { src: Ford, title: "Ford", link: GenerateLink.ForSearch("ford") },
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex + visibleItems;
      return nextIndex >= categories.length ? 0 : nextIndex;
    });
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex - visibleItems;
      return nextIndex < 0 ? prevIndex : nextIndex;
    });
  };

  return (
    <>
      <div className={styles.featuredProductContainer}>
        <h3 className={styles.featuredProductTitle}>FEATURED PRODUCTS</h3>
        <div className={styles.carousel}>
          <div className={styles.carouselInner} style={{ transform: `translateX(-${currentIndex * (100 / visibleItems)}%)` }}>
            {categories.map((category, index) => (
              <div key={index} className={styles.card} style={{ flexBasis: `${100 / visibleItems}%` }}>
                <a href={category.link}>
                  <div className={styles.cardImage}>
                    <img src={category.src} alt={category.title} className={styles.categoryImage} />
                    <div className={styles.overlay}>
                      <div className={styles.cardTitle}>{category.title}</div>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
          <button className={styles.arrow} onClick={prevSlide}>
            ❮
          </button>
          <button className={styles.arrow} onClick={nextSlide}>
            ❯
          </button>
        </div>
      </div>
      <div className={styles.topBrandsContainer}>
        <div className={styles.halfSection}>
          <div className={styles.manufacturerSection}>
            <h3>TOP BRANDS</h3>
            <div className={styles.brandCarousel}>
              <div className={styles.brandCategoryWrapper}>
                {manufacturers.map((manufacturer, index) => (
                  <div key={index} className={styles.brandcard}>
                    <a href={manufacturer.link}>
                      <div className={styles.brandCardImage}>
                        <img src={manufacturer.src} alt={manufacturer.title} className={styles.brandImage} />
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategorySection;
