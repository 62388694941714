import { gql } from "@shane32/graphql";
import { shoppingCartPartialFields, IShoppingCart } from "../queries/ShoppingCartQuery";

export const CartSetDestinationMutation = gql`
  ${shoppingCartPartialFields}

  mutation CartSetDestination($countryId: ID!, $stateProvinceId: ID, $postalCode: String) {
    v1 {
      cart {
        setDestination(countryId: $countryId, stateProvinceId: $stateProvinceId, postalCode: $postalCode) {
          ...ShoppingCartPartialFields
        }
      }
    }
  }
`;

// Everything below this line is being used currently, but eventually needs
// to switch to use graphql-codegen for types and queries/mutations.
export interface ICartSetDestinationResult {
  v1: {
    cart: {
      setDestination: IShoppingCart;
    };
  };
}

export interface ICartSetDestinationVariables {
  countryId: string;
  stateProvinceId: string | null;
  postalCode: string | null;
}
