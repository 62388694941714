import { gql } from "@shane32/graphql";
import { IPriceAndAvailability } from "./ProductPageQuery";

export const OrderSummaryQuery = gql`
  query OrderSummary($orderId: ID!) {
    v1 {
      my {
        order(id: $orderId) {
          id
          billingAddress {
            address1
            address2
            city
            company
            country {
              id
            }
            email
            firstName
            lastName
            phoneNumber
            stateProvince {
              id
            }
            zipPostalCode
          }
          createdOnUtc
          customOrderNumber
          orderItems {
            id
            product {
              id
              name
              additionalShippingCharge
              manufacturerPartNumber
              approvedRatingSum
              approvedTotalReviews
              priceAndAvailability {
                allowedQuantities
                backorderAvailabilityRange
                canNotify
                oldPrice
                orderMinimumQuantity
                orderMaximumQuantity
                preorderAvailabilityStartDateTime
                price
                status
                stock
              }
              manufacturers {
                displayOrder
                manufacturer {
                  displayOrder
                  id
                  name
                  picture {
                    imageUrl
                  }
                }
              }
              picture {
                thumbnail(size: 100) {
                  url
                }
              }
              shipSeparately
              sku
              vendor {
                name
              }
            }
            quantity
          }
          orderTotal
          orderTax
          orderSubtotalExclTax
          orderShippingExclTax
          shipments {
            id
            deliveryDateUtc
            shipmentItems {
              id
              quantity
              orderItem {
                id
              }
            }
            shippedDateUtc
            totalWeight
            trackingNumber
          }
          shippingAddress {
            address1
            address2
            city
            company
            country {
              id
            }
            email
            firstName
            lastName
            phoneNumber
            stateProvince {
              id
            }
            zipPostalCode
          }
        }
      }
    }
  }
`;

export interface IOrderSummaryQuery {
  v1: {
    my: {
      order: IOrder;
    } | null;
  };
}

export interface IOrderSummaryQueryVariables {
  orderId: string;
}

export interface IOrder {
  id: string;
  billingAddress: {
    // ORDER DETAILS
    address1: string | null;
    address2: string | null;
    city: string | null;
    company: string | null;
    country: {
      id: string;
    };
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    phoneNumber: string | null;
    stateProvince: {
      id: string;
    } | null;
    zipPostalCode: string | null;
  };
  createdOnUtc: string; // ORDER DETAILS
  customOrderNumber: string | null; // ORDER DETAILS
  orderTotal: number;
  orderTax: number;
  orderSubtotalExclTax: number;
  orderShippingExclTax: number;
  orderItems: IOrderItem[];
  shipments: Array<{
    deliveryDateUtc: string | null;
    id: string;
    shipmentItems: Array<{
      id: string;
      quantity: number;
      orderItem: {
        id: string;
      } | null;
    }>;
    shippedDateUtc: string | null;
    totalWeight: number | null;
    trackingNumber: string;
  }>;
  shippingAddress: {
    // ORDER DETAILS
    address1: string | null;
    address2: string | null;
    city: string | null;
    company: string | null;
    country: {
      id: string;
    };
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    phoneNumber: string | null;
    stateProvince: {
      id: string;
    } | null;
    zipPostalCode: string | null;
  } | null;
}

export interface IOrderItem {
  id: string;
  product: {
    id: string;
    name: string;
    additionalShippingCharge: number;
    manufacturerPartNumber: string | null;
    approvedRatingSum: number;
    approvedTotalReviews: number;
    priceAndAvailability: IPriceAndAvailability;
    manufacturers: Array<{
      displayOrder: number;
      manufacturer: {
        displayOrder: number;
        id: string;
        name: string;
        picture: {
          imageUrl: string;
        } | null;
      };
    }>;
    picture: {
      thumbnail: {
        url: string;
      };
    };
    shipSeparately: boolean;
    sku: string | null;
    vendor: {
      name: string;
    } | null;
  };
  quantity: number;
}
