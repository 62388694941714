import type {
  AutocompleteConnectorParams,
  AutocompleteWidgetDescription,
} from "instantsearch.js/es/connectors/autocomplete/connectAutocomplete";
import connectAutocomplete from "instantsearch.js/es/connectors/autocomplete/connectAutocomplete";
import { useConnector } from "react-instantsearch";
import { Distinct } from "../../../helpers/Array";
import style from "./AutoComplete.module.scss";

export type UseAutocompleteProps = AutocompleteConnectorParams;

// interface IAutoCompleteProps {
//     item: any;
//     deselect?: () => void;
//     itemMax: number;
//     productAmount: number;
//     highLightItem?: (index: number) => void;
//     goToProductPage: (id: any, seoName: any) => void;
//     searchIt?: number;
//     selected?: number;
// };

// interface IautoCompleteItemProps extends IAutoCompleteProps{
//     i: number;
//     initSearch: (props: IAutoCompleteProps, index: number) => void;
// }

export default function AutoComplete(props: {
  hideDesktopSuggest?: boolean;
  itemMax: number;
  productAmount: number;
  deselect?: () => void;
  highLightItem?: (index: number) => void;
  goToProductPage: (id: any, seoName: any) => void;
  SearchSuggestion: (e: string) => void;
  searchIt?: number;
  selected?: number;
}) {
  //when using index version cooment out this
  const { indices } = useAutocomplete();

  const index = indices[0];

  // const fitmentCatgorys = index.hits
  //     .filter((x) => x.category && x.fitment[0])
  //     .map((x: any) => `${x.category} ${x.fitment[0]}`);
  const lastSuggestionIndex = props.itemMax - props.productAmount;

  //no duplicates
  // const productsList = index.hits.slice(lastSuggestionIndex - 1, lastSuggestionIndex + props.productAmount - 1);
  //duplicates
  // const productsList = index.hits.slice(0, props.productAmount - 1);
  // const initSearch = (tt: IAutoCompleteProps, index: number) => {
  //     const xt = tt.item;
  //     if (tt.searchIt === index && tt.deselect) {
  //         tt.deselect();

  //         history.push(
  //             GenerateLink.ForProduct(
  //                 xt.objectID,
  //                 xt.seoName
  //             )
  //         );
  //     }
  // }
  return (
    <div className={style["container"] + " " + (props.hideDesktopSuggest ? style["desktopHide"] : "")}>
      {/* <div className={style["container"]}> */}
      <div className={style["fullWidth"]}>
        {/* <div className={style["blank"]}></div> */}
        <div className={style["dropdown"]}>
          {/* will be more effective once our suggestions build up */}
          {/* this works dont delete */}
          {/* <Index indexName="PRODUCTS_MASTER_SUGGESTIONS">
                    <AutoCompItems
                    index={0}
                    selected={props.SearchSuggestion}
                    junk={Suggestion}
                    ></AutoCompItems>
                </Index>
                <Index indexName="PRODUCTS_MASTER">
                    <AutoCompItems
                        index={1}
                        selected={props.SearchSuggestion}
                        junk={Product}
                    ></AutoCompItems>
                </Index> */}
          {
            // Distinct(index.hits.map((x) => x.SearchTerm))
            Distinct(
              index.hits
                .map((x) => {
                  if (x.SearchTerm) {
                    return x.SearchTerm;
                  }
                  var ar = [x.category, x.part];
                  if (x.interchange) {
                    ar = ar.concat(x.interchange);
                  }

                  if (x.fitment) {
                    ar = ar.concat(x.fitment);
                  }
                  return ar;
                })
                .flat()
            )
              .slice(0, lastSuggestionIndex)
              .map((x: any, i: number) => {
                // console.log(x);
                const term = x;
                const index = i;
                if (props.searchIt === index) {
                  props.SearchSuggestion(term);
                }
                return (
                  <div
                    key={index}
                    onMouseEnter={() => props.highLightItem && props.highLightItem(index)}
                    onClick={() => props.SearchSuggestion(term)}
                    className={style["item"] + " " + (index === props.selected ? style["selected"] : null)}
                  >
                    <div>{term}</div>
                  </div>
                );
              })
          }
          {/* {productsList.length > 0 ? (
                        <div className={style["item"] + " " + style["title"]}>
                            <div>Products</div>
                        </div>
                    ) : null} */}

          {/* {
                        // index.hits.slice(props.itemMax - props.productAmount - 1, props.productAmount - 1).map((x: any, i: number) =>{
                        productsList.map((x: any, i: number) => {
                            const index =
                                i + props.itemMax - props.productAmount;
                            if (props.searchIt === index && props.deselect) {
                                props.deselect();

                                history.push(
                                    GenerateLink.ForProduct(
                                        x.objectID,
                                        x.seoName
                                    )
                                );
                            }
                            return (
                                <div
                                    key={index}
                                    onMouseEnter={() =>
                                        props.highLightItem &&
                                        props.highLightItem(index)
                                    }
                                    onClick={() =>
                                        props.goToProductPage(
                                            x.objectID,
                                            x.seoName
                                        )
                                    }
                                    className={
                                        style["item"] +
                                        " " +
                                        (index === props.selected
                                            ? style["selected"]
                                            : null)
                                    }
                                >
                                    <div>{x.title}</div>
                                    <div className={style["imgHolder"]}>
                                        <img alt="" src={x.picture}></img>
                                    </div>
                                </div>
                            );
                        })
                    } */}
        </div>
        {/* <div className={style["blank"]}></div> */}
      </div>
    </div>
  );
}

export function useAutocomplete(props?: UseAutocompleteProps) {
  return useConnector<AutocompleteConnectorParams, AutocompleteWidgetDescription>(connectAutocomplete, props);
}

//dont Delete!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// function Suggestion(props: {x: any, i: number, selected : (label: string) => void} ){
//     return (
//         <div key={props.i} onClick={() => props.selected(props.x.query)} className={style["item"]}>
//             <div>
//                 {props.x.query}
//             </div>
//         </div>
//         )
// }

// function Product(props: IautoCompleteItemProps){
//         const x = props.item;

//         const index = props.i + props.itemMax - props.productAmount;
//         props.initSearch(props, index);
//         // if (props.searchIt === index && props.deselect) {
//         //     props.deselect();

//         //     history.push(
//         //         GenerateLink.ForProduct(
//         //             x.objectID,
//         //             x.seoName
//         //         )
//         //     );
//         // }
//         // return (
//         //     <div
//         //         key={index}
//         //         onMouseEnter={() =>
//         //             props.highLightItem &&
//         //             props.highLightItem(index)
//         //         }
//         //         onClick={() =>
//         //             props.goToProductPage(
//         //                 x.objectID,
//         //                 x.seoName
//         //             )
//         //         }
//         //         className={
//         //             style["item"] +
//         //             " " +
//         //             (index === props.selected
//         //                 ? style["selected"]
//         //                 : null)
//         //         }
//         //     >
//         //         <div>{x.title}</div>
//         //         <div className={style["imgHolder"]}>
//         //             <img alt="" src={x.picture}></img>
//         //         </div>
//         //     </div>
//         // );
// }

//dont Delete!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// function Product(props: {x: any, i: number, selected?: number,      itemMax: number, productAmount: number, goToProductPage: (id: any, seoName: any) => void; , highLightItem?: (index: number) => void} ){
//      const index = props.i + props.itemMax - props.productAmount;

//     return (
//         <div
//         key={props.i}
//         onMouseEnter={() =>
//             props.highLightItem &&
//             props.highLightItem(index)
//         }
//         onClick={() =>
//             props.goToProductPage(
//                 props.x.objectID,
//                 props.x.seoName
//             )
//         }
//         className={
//             style["item"] +
//             " " +
//             (index === props.selected
//                 ? style["selected"]
//                 : null)
//         }
//     >
//         <div>{props.x.title}</div>
//         <div className={style["imgHolder"]}>
//             <img alt="" src={props.x.picture}></img>
//         </div>
//     </div>
//     )
//     // return (
//     // <div key={props.i} onClick={() => props.selected(props.x.title)} className={style["item"]}>
//     //     <div>
//     //         {props.x.title}
//     //     </div>
//     //     <div className={style["imgHolder"]}>
//     //         <img src={props.x.picture}></img>
//     //     </div>
//     // </div>
//     // )
// }
