import { gql } from "@shane32/graphql";

export const MachineManufacturerWithModelDiagramsQuery = gql`
  query MachineManufacturerWithModelDiagrams($machineManufacturerId: ID!) {
    v1 {
      machineManufacturers(id: $machineManufacturerId, diagramsRequired: true) {
        id
        name
        machineModels(diagramsRequired: true) {
          id
          name
        }
      }
    }
  }
`;

export interface IMachineManufacturerWithModelDiagramsQuery {
  v1: {
    machineManufacturers: IMachineManufacturersWithDiagrams[];
  };
}

export interface IMachineManufacturersWithDiagrams {
  id: string;
  name: string;
  machineModels: IMachineModelsWithDiagrams[];
}

export interface IMachineModelsWithDiagrams {
  id: string;
  name: string;
}

export interface IMachineManufacturerWithModelDiagramsQueryVariables {
  machineManufacturerId: string;
}
