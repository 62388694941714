import { gql } from "@shane32/graphql";

export const WarehouseQuery = gql`
  query Warehouse($productId: ID) {
    v1 {
      product(id: $productId) {
        warehouse {
          id
          name
          address {
            address1
            address2
            city
            country {
              id
            }
            stateProvince {
              id
              name
            }
            zipPostalCode
            phoneNumber
          }
        }
      }
    }
  }
`;

export interface IWarehouseQuery {
  v1: {
    product: {
      warehouse: IWarehouse | null;
    } | null;
  };
}

interface IAddress {
  address1: string | null;
  address2: string | null;
  city: string | null;
  country: {
    id: string;
  };
  stateProvince: {
    id: string;
    name: string;
  };
  zipPostalCode: string | null;
  phoneNumber: string | null;
}

export interface IWarehouse {
  id: string;
  name: string;
  address: IAddress | null;
}

export interface IWarehouseQueryVariables {
  productId: string;
}
