import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GlobalContext from "../../contexts/GlobalContext";
import GenerateLink from "../../helpers/GenerateLink";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import AutoCompleteContainer from "../search_bar/AutoComplete/AutoCompleteContainer";
import AccountTab from "./AccountTab";
import styles from "./ScrollNavBar.module.scss";

const ScrollNavBar = () => {
  const globalContext = React.useContext(GlobalContext);
  // const context = React.useContext(AuthContext);
  const [offset, setOffset] = useState(false);
  const globalClickHandler = () => {
    globalContext.closeOverlay();
  };
  const salesBannerRef = React.useRef<HTMLDivElement>(null);

  const { width } = useWindowDimensions();
  useEffect(() => {
    const onScroll = () => {
      const threshHold = width > 576 ? 290 + 4 : 71 + 72 + 6;
      if (window.pageYOffset > threshHold && !offset) {
        setOffset(true);
      } else if (window.pageYOffset <= threshHold) {
        setOffset(false);
      }
    };
    // clean up code
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  });

  useEffect(() => {
    if (offset && width < 576) {
      var salesBannerHeight = salesBannerRef.current?.clientHeight ?? 0;
      document.body.style.setProperty("margin-bottom", `${salesBannerHeight}px`);
    } else {
      document.body.style.setProperty("margin-bottom", `0px`);
    }
    return () => {
      document.body.style.setProperty("margin-bottom", `0px`);
    };
  }, [offset, width]);

  return (
    <div className={styles.container}>
      <div className={styles.bar}>
        <div className={styles.left}>
          <div className={styles.menu} onClick={globalContext.toggleDepartments} />
          <div className={styles.logo} />
        </div>
        <div className={styles["search-container"]}>
          <AutoCompleteContainer borderType="fine" />
        </div>
        <div className={styles.right}>
          <AccountTab accountClick={globalContext.toggleDepartments} loggedInDisplay="Account" color="white" />
        </div>
      </div>
      <div className={styles.links}>
        <Link onClick={globalClickHandler} to={GenerateLink.ForHelp()}>
          Help
        </Link>
        <Link onClick={globalClickHandler} to={GenerateLink.ForTeam()}>
          Meet The Team
        </Link>
        <Link onClick={globalClickHandler} to={GenerateLink.ForSellWithUs()}>
          Sell With Us
        </Link>
      </div>
    </div>
  );
};

export default ScrollNavBar;
