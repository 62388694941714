import { gql } from "@shane32/graphql";
import { IShoppingCartWithShippingMethods, shoppingCartPartialWithReturnShippingMethodsFields } from "../queries/ShoppingCartQuery";

export const CartSetDestinationExternalPaymentMutation = gql`
  ${shoppingCartPartialWithReturnShippingMethodsFields}

  mutation CartSetDestinationExternalPayment($countryId: ID!, $stateProvinceId: ID, $postalCode: String) {
    v1 {
      cart {
        setDestination(countryId: $countryId, stateProvinceId: $stateProvinceId, postalCode: $postalCode) {
          ...ShoppingCartPartialWithReturnShippingMethodsFields
        }
      }
    }
  }
`;

// Everything below this line is being used currently, but eventually needs
// to switch to use graphql-codegen for types and queries/mutations.
export interface ICartSetDestinationExternalPaymentResult {
  v1: {
    cart: {
      setDestination: IShoppingCartWithShippingMethods;
    };
  };
}

export interface ICartSetDestinationExternalPaymentVariables {
  countryId: string;
  stateProvinceId: string | null;
  postalCode: string | null;
}
