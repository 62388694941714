import { gql, GraphQLError, IQueryResult, useMutation, useQuery } from "@shane32/graphql";
import { AuthContext } from "@zboxglobal/zboxauth";
import React, { useState } from "react";
import Loading from "../../../../components/loading/Loading";
import styles from "./BookMark.module.scss";

interface IBookMarkDiagramResult {
  v1: {
    diagram: {
      favoriteDiagram: boolean;
    };
  };
}

interface IBookMarkDiagramVariables {
  diagramId: string;
  favorite: boolean;
}

interface IBookMarkedDiagramQuery {
  v1: {
    my: {
      customerDiagrams: {
        relationType: string;
        diagram: {
          id: string;
        };
      }[];
    };
  };
}

const useBookMarkDiagramMutation = function (): [(data: IBookMarkDiagramVariables) => Promise<IQueryResult<IBookMarkDiagramResult>>] {
  const mut = gql`
    mutation UseBookMarkDiagram($diagramId: ID!, $favorite: Boolean!) {
      v1 {
        diagram {
          favoriteDiagram(diagramId: $diagramId, favorite: $favorite)
        }
      }
    }
  `;
  const [mutation] = useMutation<IBookMarkDiagramResult, IBookMarkDiagramVariables>(mut);
  return [(data) => mutation({ variables: data })];
};

const QueryBookMarkedDiagram = function () {
  const query = gql`
    query BookMarkedDiagram {
      v1 {
        my {
          customerDiagrams {
            relationType
            diagram {
              id
            }
          }
        }
      }
    }
  `;

  return useQuery<IBookMarkedDiagramQuery>(query);
};

const BookMark = (props: { diagramId: string }) => {
  const authContext = React.useContext(AuthContext);
  const [diagramIsBookmarked, setDiagramIsBookmarked] = useState(false);
  const [mutationRunning, setMutationRunning] = useState(false);
  const [bookMarkDiagram] = useBookMarkDiagramMutation();
  const { loading, error, data, errors } = QueryBookMarkedDiagram();

  React.useEffect(() => {
    setDiagramIsBookmarked(
      (data?.v1.my !== null &&
        data?.v1.my.customerDiagrams.some((x) => x.diagram.id === props.diagramId && x.relationType === "FAVORITED")) === true
    );
  }, [data?.v1.my, data?.v1.my?.customerDiagrams, props.diagramId]);

  if (loading || !data || !data.v1) return <Loading />;
  if (error || errors) return <div />;

  const toggleFav = () => {
    if (mutationRunning) return;
    setMutationRunning(true);
    return authContext.client.EnsureGuest(true).then(() => {
      return bookMarkDiagram({
        diagramId: props.diagramId,
        favorite: !diagramIsBookmarked,
      }).then(
        (x) => {
          if (data) {
            let newCustomerDiagram = {
              relationType: diagramIsBookmarked ? "UNFAVORITED" : "FAVORITED",
              diagram: { id: props.diagramId },
            };
            if (data?.v1.my.customerDiagrams) {
              data.v1.my.customerDiagrams = [
                ...data.v1.my.customerDiagrams.filter((y) => y.diagram.id !== props.diagramId),
                newCustomerDiagram,
              ];
            } else {
              data.v1.my.customerDiagrams = [newCustomerDiagram];
            }
          }
          setMutationRunning(false);
        },
        (ret: GraphQLError) => {
          setMutationRunning(false);
        }
      );
    });
  };

  return (
    <div className={styles["bookmark-container"]} onClick={toggleFav}>
      <div className={diagramIsBookmarked ? styles["bookmarked"] : styles["not-bookmarked"]} />
      <div className={styles["bookmark-text"]}>Bookmark</div>
    </div>
  );
};

export default BookMark;
