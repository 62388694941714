import { InstantSearch } from "react-instantsearch";
import { getSearchClient } from "../../../pages/algolia_search/algoliaClient";
// import { MainAlgoliaIndex } from "../../../pages/algolia_search/AlgoliaHelpers";
import AutoCompleteSearchBar, { BorderType } from "../AutoCompleteSearchBar";

export default function AutoCompleteContainer(props: { borderType?: BorderType }) {
  return (
    //always use autocomplete if mobile
    //only use use desktop when useSearchSuggest
    <InstantSearch searchClient={getSearchClient()} indexName={process.env.REACT_APP_ALGOLIA_SUGGESTION_INDEX ?? ""} routing={false}>
      <AutoCompleteSearchBar borderType={props.borderType} />
    </InstantSearch>
  );
}
