import { createInfiniteHitsSessionStorageCache } from "instantsearch.js/es/lib/infiniteHitsCache";
import React from "react";
import { Configure, InstantSearch } from "react-instantsearch";
import DefaultSuspense from "../../components/loading/DefaultSuspense";
import Navbar from "../../components/navbar/Navbar";
import Overlay from "../../components/overlay/Overlay";
import AutoCompleteSearchBar from "../../components/search_bar/AutoCompleteSearchBar";
import GlobalContext from "../../contexts/GlobalContext";
import { Hits, MainAlgoliaIndex, Meta, Stats, useStats } from "./AlgoliaHelpers";
import InsightsMiddleware from "./AlgoliaMiddleware";
import { InstantSearchResults } from "./Results";
import styles from "./Search.module.scss";
import SideBarSearchFilters from "./SideBarFilters";
import { getSearchClient } from "./algoliaClient";

declare const window: Window & {
  aa: any;
};

export default function Search() {
  return (
    <>
      <Overlay></Overlay>
      {/* <div
                onClick={() => { globalContext.closeOverlay(); globalContext.setFitmentSideBar("ClOSED") }}
                className={globalContext.isAccountOpen || (globalContext.fitmentSideBarState !== "ClOSED") || globalContext.isDepartmentsOpen || globalContext.isMobileFiltersOpen ? appStyles.overlay : ""} /> */}
      <DefaultSuspense>
        {/* if one day we want custom routing try
                iv gotten it to work but it is not consistent and buggy
                https://codesandbox.io/s/github/algolia/doc-code-samples/tree/master/react-instantsearch-hooks/routing-seo-friendly
                <InstantSearch searchClient={searchClient} indexName="PRODUCTS_MASTER" routing={searchRouting}>
                <Configure ruleContexts={[]} /> */}
        <InstantSearch searchClient={getSearchClient()} indexName={MainAlgoliaIndex} routing={true}>
          <Configure clickAnalytics={true} userToken="zbox" />
          <Navbar>
            {/* <AlgoliaSearchBar setFocus={(b: boolean) => console.log("go to fancy search page")} /> */}
            <AutoCompleteSearchBar hideDesktopSuggest={true} />
          </Navbar>
          <SearchBody />
          {/*InsightsMiddleware must be on bottom or will create a rerender and search url will be wiped*/}
          {window.aa != null ? <InsightsMiddleware /> : null}
        </InstantSearch>
      </DefaultSuspense>
    </>
  );
}

// This needs to sit outside of the SearchBody to maintain a stable reference,
// else it will be re-created on every render and cause performance issues.
// This cache is used to store the infinite hits in the session storage,
// and does not rely on any component lifecycle or state, so it is safe
// to create it outside of the react rendering process.
const infiniteHitsCache = createInfiniteHitsSessionStorageCache();

function SearchBody() {
  const globalContext = React.useContext(GlobalContext);
  const { nbHits, query } = useStats();

  Meta(query, nbHits);

  return (
    <div className={styles["search-results-container"]}>
      <SideBarSearchFilters
        toggleMobileFilters={globalContext.toggleMobileFilters}
        isOpen={globalContext.isMobileFiltersOpen}
      ></SideBarSearchFilters>
      <div className={styles["search-results-content"]}>
        <div className={styles["card-header"]}>
          <div className={styles["search-results-header-row"]}>
            <Stats nbHits={nbHits} query={query} />
            <button
              className={`btn secondary flat short fs-small ${styles["filter-button"]}`}
              onClick={() => globalContext.toggleMobileFilters()}
            >
              Filters
            </button>
          </div>
        </div>
        {nbHits === 0 ? (
          <div className={styles["noResultsFound"]}>
            <h1>No Results Found</h1>
            <h3>Make sure that all words are spelled correctly or try different keywords</h3>
          </div>
        ) : (
          <InstantSearchResults hits={Hits} cache={infiniteHitsCache} />
        )}
      </div>
    </div>
  );
}
