import { gql } from "@shane32/graphql";
import { IPriceAndAvailability } from "./ProductPageQuery";
export const OrderPartialQuery = `
         id
         customerId
         isGuestOrder
         canRegister
         canRequestReturn
         canCancelOrder
         billingAddress {
           address1
           address2
           city
           company
           country {
             id
           }
           email
           firstName
           lastName
           phoneNumber
           stateProvince {
             id
           }
           zipPostalCode
         }
         createdOnUtc
         customOrderNumber
         clubOrderGuid
         estArrivalDate
         purchaseOrder
         orderItems {
           orderItemGuid
           id
           product {
             id
             name
             reviews{
                totalCount
             }
             seoName
             additionalShippingCharge
             manufacturerPartNumber
             manufacturers {
               displayOrder
               manufacturer {
                 displayOrder
                 id
                 name
                 picture {
                   imageUrl
                 }
               }
             }
             priceAndAvailability {
               allowedQuantities
               backorderAvailabilityRange
               oldPrice
               orderMaximumQuantity
               orderMinimumQuantity
               preorderAvailabilityStartDateTime
               price
               status
               stock
               canNotify
             }
             picture {
               thumbnail(size: 100) {
                 url
               }
             }
             shipSeparately
             sku
             vendor {
               name
             }
           }
           quantity
           unitPriceExclTax
           reference
         }
         orderStatus
         orderTotal
         paymentStatus
         shipments {
           id
           deliveryDateUtc
           shipmentItems {
             id
             quantity
             orderItem {
               id
             }
           }
           shippedDateUtc
           totalWeight
           trackingNumber
           trackingNumberUrl
         }
         shippingAddress {
           address1
           address2
           city
           company
           country {
             id
           }
           email
           firstName
           lastName
           phoneNumber
           stateProvince {
             id
           }
           zipPostalCode
         }
         zboxShippingMethod
         shippingStatus
        `;
export const OrdersAndReturnsQuery = gql`
query OrdersAndReturnsQuery {
 v1 {
    my {
      orders {
        totalCount
        pageInfo {
          endCursor
          startCursor
        }
        items {
          ${OrderPartialQuery}
        }
      }
    }
  }
}`;

interface IOrders {
  totalCount: number;
  pageInfo: IPageInfo;
  edges: IOrderEdges[];
  items: IOrder[];
}

interface IPageInfo {
  //hasNextPage: boolean;
  //hasPreviousPage: boolean;
  startCursor: string;
  endCursor: string;
}

export interface IOrder {
  id: string;
  customerId: string;
  isGuestOrder: boolean;
  canRegister: boolean;
  canRequestReturn: boolean;
  canCancelOrder: boolean;
  billingAddress: {
    // ORDER DETAILS
    address1: string | null;
    address2: string | null;
    city: string | null;
    company: string | null;
    country: {
      id: string;
    };
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    phoneNumber: string | null;
    stateProvince: {
      id: string;
    } | null;
    zipPostalCode: string | null;
  };
  createdOnUtc: string; // ORDER DETAILS
  customOrderNumber: string | null; // ORDER DETAILS
  clubOrderGuid: string;
  estArrivalDate: string | null;
  // NEED  TO SPLIT UP FOR SUMMARY AND THIS QUERY //
  orderItems: IOrderItem[];

  //orderShippingExclTax: number;  // ARE THESE GENERATED BY THE FRONT END RIGHT NOW?
  //orderShippingInclTax: number; // ARE THESE GENERATED BY THE FRONT END RIGHT NOW?
  orderStatus: OrderStatus;
  //orderTax: number; // ARE THESE GENERATED BY THE FRONT END RIGHT NOW?
  orderTotal: number;
  paymentStatus: string;
  purchaseOrder: string | null;
  shipments: Array<{
    deliveryDateUtc: string | null;
    id: string;
    shipmentItems: Array<{
      id: string;
      quantity: number;
      orderItem: {
        id: string;
      } | null;
    }>;
    shippedDateUtc: string | null;
    totalWeight: number | null;
    trackingNumber: string | null;
    trackingNumberUrl: string | null;
  }>;
  shippingAddress: {
    // ORDER DETAILS
    address1: string | null;
    address2: string | null;
    city: string | null;
    company: string | null;
    country: {
      id: string;
    };
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    phoneNumber: string | null;
    stateProvince: {
      id: string;
    } | null;
    zipPostalCode: string | null;
  } | null;
  zboxShippingMethod: string | null; //ORDER DETAILS
  shippingStatus: string;
}

export enum OrderStatus {
  Pending = "PENDING",
  Processing = "PROCESSING",
  Complete = "COMPLETE",
  Cancelled = "CANCELLED",
}

export interface IOrderItem {
  orderItemGuid: string;
  id: string;
  product: {
    id: string;
    name: string;
    reviews: {
      totalCount: number | null;
    };
    seoName: string | null;
    additionalShippingCharge: number;
    manufacturerPartNumber: string | null;
    priceAndAvailability: IPriceAndAvailability;
    manufacturers: Array<{
      displayOrder: number;
      manufacturer: {
        displayOrder: number;
        id: string;
        name: string;
        picture: {
          imageUrl: string;
        } | null;
      };
    }>;
    picture: {
      thumbnail: {
        url: string;
      };
    } | null;
    shipSeparately: boolean;
    sku: string | null;
    vendor: {
      name: string;
    } | null;
  };
  quantity: number;
  unitPriceExclTax: number;
  reference: string | null;
}

interface IOrderEdges {
  cursor: string;
  node: IOrder;
}

export interface IOrdersAndReturnsQuery {
  v1: {
    my: {
      orders: IOrders;
    } | null;
  };
}
